
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

@font-face {
  font-family: 'bebas-neue';
  src: local('bebas-neue'), url('../fonts/BebasNeue-Regular.woff') format('woff');
}

.logotype {
  font-family: 'bebas-neue';
  letter-spacing: 0.333rem;
  line-height: 1 !important;
  margin: 0 !important;
}

.effect-slide {
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.77,0,0.175,1);
  transform: translateX(20rem, 0, 0px);
}

.search-slide {
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.77,0,0.175,1);
}

.slide-in {
  animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
}
  
@keyframes slide-in {
  100% { transform: translateX(0%); }
}
  
@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-20rem); }
}

.engagement-active {
  transition-timing-function: cubic-bezier(0.77,0,0.175,1);
  transition-duration: 0.2s;
  flex-grow: 3;
}

.engagement-inactive {
  transition-timing-function: cubic-bezier(0.77,0,0.175,1);
  transition-duration: 0.2s;
  flex-grow: 1;
}

.marquee {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 10s linear infinite;
}

.logo-effect .smiles-and-cries {
  animation: morph  2s cubic-bezier(0.77,0,0.175,1) forwards;
}

@keyframes morph {
  0%  { transform: translate(0, 0); }
  100% { transform: translate(50%, 0); }
}

@keyframes morphReturn {
  0%  { transform: translate(0, 0); }
  50% { transform: translate(50%, 0); }
  100% { transform: translate(100%, 0); }
}

.post-img {
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.fade-in { 
  animation: fadeIn 5s cubic-bezier(0.77,0,0.175,1) forwards; 
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}